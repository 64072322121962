import axios from "../../utilities/axios";
import React from "react";
import { Button, Modal, Table, Panel, Toggle, DatePicker, SelectPicker } from "rsuite";
import { AxiosSelectPicker } from "../formfields/AxiosSelectPicker";
import dayjs from "dayjs";
import TablePagination from "rsuite/lib/Table/TablePagination";
import _ from "lodash"

const { Column, HeaderCell, Cell } = Table;

interface CustomModalProps {
    widthModal?: string;
    widthNumber?: number;
    closeModal?: () => void;
    onCancel?: () => void;
    onConfirm?: () => void;
    funcaoclose?: () => void;
    confirmColor?: 'red' | 'green' | 'blue' | 'orange' | 'cyan' | 'yellow' | 'violet'
    cancelColor?: 'red' | 'green' | 'blue' | 'orange' | 'cyan' | 'yellow'  | 'violet' 
    title?: any;
    body?: any;
    show?: boolean;
    showConfirm?: boolean;
    showbotaoclose?: boolean;
    loading?: boolean;
    loadings?: boolean;
    confirmText?: string;
    cancelText?: string;
}


export class CustomModal extends React.Component<CustomModalProps, {}> {
    public state: any = {
        loading: this.props.loadings
    }

    render() {
        if (this.props.show === false) {
            return null;

        }
        return (
            <Modal show={this.props.show} className={this.props.widthModal} width={this.props.widthNumber} onHide={this.props.closeModal}>
                <Modal.Header>
                    <h4>{this.props.title ?? "Deseja mesmo sair?"}</h4>
                </Modal.Header>
                <Modal.Body>
                    {this.props.body}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={this.props.loading} loading={this.props.loading} onClick={this.props.onCancel} color={this.props?.cancelColor ?? undefined}>
                        {this.props?.cancelText ?? 'Cancelar'}
                    </Button>
                    {this.props.showConfirm !== false && <Button loading={this.props.loading} onClick={this.props.onConfirm} color={this.props?.confirmColor ?? 'red'}>
                        {this.props?.confirmText ?? 'Confirmar'}
                    </Button>}
                    {this.props.showbotaoclose == true && <Button disabled={this.props.loading} onClick={this.props.funcaoclose} >
                        Fechar
                    </Button>}

                </Modal.Footer>
            </Modal>
        );
    }
}
