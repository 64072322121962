import React, { useContext, useEffect, useState } from "react"
import { Button, CheckPicker, DateRangePicker, Divider, Icon, Input, Panel, Placeholder } from "rsuite"
import { io } from "../../utilities/io";
import '@asseinfo/react-kanban/dist/styles.css'
import dayjs from "dayjs";
//@ts-ignore
import Board, { moveCard } from "@asseinfo/react-kanban"
import GlobalLoading from "../../components/globalLoading";
import axios from "../../utilities/axios";
import { InputSelect } from "../../components/InputSelect";

const KanbanView = () => {
    const [data, setData] = useState<any>([])
    const [users, setUsers] = useState<any>([])
    const [filter, setFilter] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)
    const [receiver, setReceiver] = useState<any>(false)
    const getData = async () => {
        axios.get("/api/v1/kanban-get", {
            params: {
                ...filter
            }
        }).then((res: any) => {
            setData(res.data)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getUsersData = async () => {
        axios.get("/api/v1/users/select", {
            params: {
                ...filter
            }
        }).then((res: any) => {
            setUsers(res.data.items)
        }).finally(() => {
        })
    }

    useEffect(() => {
        getData()
    }, [receiver])

    useEffect(() => {
        if (data?.length <= 0) {
            getUsersData()
            getData()
            io.on("kanban:receiver", async (data: any) => {
                setReceiver(dayjs().unix())
            })
        }
    }, [])



    const moveCardFn = async (currentCard: any, from: any, to: any, teste: any) => {
        io.emit("kanban:emit", { currentCard, from, to, teste })
        let newData = moveCard(data, from, to)
        setData(newData)
    }
    const getColumn = (card: any) => {
        const column = data.columns.filter((column: any) => column.cards.includes(card))
        return column[0]
    }

    const getGradient = (card: any) => {
        const column = getColumn(card)
        const title = column.title
        if (title.includes("Em Aberto")) {
            return {
                background:
                    "linear-gradient(65.35deg, rgba(65, 65, 65, 0.67) -1.72%, rgb(1, 4, 4) 163.54%)",
            };
        } else if (title.includes("Orçadas")) {
            return {
                background:
                    "linear-gradient(65.35deg, rgb(84, 125, 177) -1.72%, rgb(0, 0, 0) 163.54%)",
            };
        } else if (title.includes("Aprovados")) {
            return {
                background:
                    "linear-gradient(65.35deg, rgba(65, 65, 65, 0.67) -1.72%, rgba(48, 220, 86) 163.54%)",
            };
        } else if (title.includes("Pendente Documentação")) {
            return {
                background:
                    "linear-gradient(65.35deg, rgba(65, 65, 65, 0.67) -1.72%, rgb(128, 98, 61) 163.54%)",
            };
        }
        else if (title.includes("Documentos Validados")) {
            return {
                background:
                    "linear-gradient(65.35deg, rgba(65, 65, 65, 0.67) -1.72%, rgba(48, 220, 86) 163.54%)",
            };
        }
        else if (title.includes("Declinados")) {
            return {
                background:
                    "linear-gradient(65.35deg, rgba(65, 65, 65, 0.67) -1.72%, rgba(220, 48, 48) 163.54%)",
            };
        }
        else if (title.includes("Expiradas")) {
            return {
                background:
                    "linear-gradient(65.35deg, rgba(65, 65, 65, 0.67) -1.72%, rgb(232, 205, 60) 163.54%)",
            };
        }
        else if (title.includes("Perdidas")) {
            return {
                background:
                    "linear-gradient(65.35deg, rgba(65, 65, 65, 0.67) -1.72%, rgba(220, 48, 48) 163.54%)",
            };
        }

    }

    const typingFilter = (field: any, value: any) => {
        var init;
        var finish;
        if (field == "deate") {
            init = value?.[0]
            finish = value?.[1]
            setFilter({ ...filter, init, finish })
        } else if (field == "budget_fk_user_id") {
            setFilter({ ...filter, budget_fk_user_id: value.toString() })
        }
        else {
            setFilter({ ...filter, [field]: value })

        }
    }

    let kanbanData = { ...data }
    return (<>
            {/* <div style={{ display: "flex", width: "100%" }}>
                <DateRangePicker onChange={(value: any) => typingFilter("deate", value)} format={"DD-MM-YYYY"} />
                <CheckPicker placeholder="Selecione..." style={{ width: "100%" }} onChange={(value: any) => typingFilter("budget_fk_user_id", value)} data={users ?? []} />
                <Button onClick={() => getData()} style={{ width: "130px" }} appearance="primary"><Icon icon={"search"} /> FILTRAR</Button>
            </div>
            <div style={{ marginLeft: 12, color: "black" }}>
                <p> Total de Cards: <b> {data?.columns?.length}</b> </p>
            </div> */}
            {isLoading && (<div style={{ width: "100%" }}>
                <Placeholder.Paragraph rows={12} />
            </div>
            )}
            * Apenas Visualização *
            {!isLoading && kanbanData && (<Board
                disableColumnDrag={true}
                // onCardDragEnd={moveCardFn}
                // initialBoard={data}
                disableCardDrag={true}
                renderCard={(item: any) => {
                    return (<>
                        <div className="card-a" style={{ background: "white", width: 240 }}>
                            <div className="card-header-a" style={getGradient(item)}>
                                <h6 className="card-title-a">#{item?.id}  {item?.title}</h6>
                                {/* <h6 className="card-title"></h6> */}
                            </div>
                            <div className='card-body-a'>
                                {/* <p>
                                    <InputSelect crud={"users"} id={item.budget_fk_user_id} field={"user_name"} />
                                </p> */}
                                <p>
                                    <strong>Descrição</strong><br />
                                    {item?.description}
                                </p>
                                <p>
                                    <strong>CPF / CNPJ</strong><br />
                                    {item?.budget_cpfcnpj}
                                </p>
                                <p>
                                    <strong>Status</strong><br />
                                    <InputSelect crud={"status_budgets"} id={item?.budget_fk_status_budget_id} field={"status_budget_name"} />
                                    {/* <InputSelect crud={"proposals_status"} id={item?.proposal_status_fk_proposals_status_id} /> */}
                                </p>


                                <Divider />
                            </div>
                            <div className="card-footer-a">
                                {dayjs(item?.budget_created_at).format("DD/MM/YYYY HH:mm")}
                                {/* <Button appearance="primary" color="violet" style={{ float: "right" }} onClick={() => {
                                    window.location.href = `/dashboard/project/budgets/${item?.id}`
                                }}><i className="fas fa-edit"></i></Button> */}
                            </div>
                        </div>
                    </>)
                }}
            >
                {kanbanData}
            </Board>)}
       
    </>)

}

export default KanbanView

