import dayjs from "dayjs";
import { measureTime } from "../../utilities/Performance";
import { Event } from "../Event";

export class ParamEvents extends Event {
    public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
        // measureTime("ParamEvents", () => {
        //     const parameterValidityAgo = this.globalProps.values?.param_validity_parameter_date;

        //     let month = parseInt(parameterValidityAgo.slice(0, 2));
        //     let year = parseInt(parameterValidityAgo.slice(2));

        //     if (month === 12) {
        //         month = 1;
        //         year += 1;
        //     } else {
        //         month += 1;
        //     }

        //     const newMonthString = month < 10 ? `0${month}` : `${month}`;
        //     const newDate = `${newMonthString}${year}`;

        //     console.log({ newDate });
        //     this.setFieldValue("param_validity_parameter_date", newDate);
        // });
    }
}