import { Event } from "../Event";

export class RolePermissionsEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value.includes("kanban")) {
            // const withKanban = [...value, "kanban-edit"];
            // console.log({ withKanban })
            // this.setFieldValue('role_permissions', withKanban);
        } else if (value.includes("kanban-read") || value.includes("kanban-edit")) {
            const withKanban = [...value, "kanban"];
            // console.log({ withKanban })
            this.setFieldValue('role_permissions', withKanban);
        }
    }
}