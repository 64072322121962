import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { Notification } from "rsuite";

export class BudgetFkChemicalClassIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value == 1 || value == 7) {
            // this.setFieldValue("budget_fk_chemical_class_id", "")
            this.setState('loadings', ['budget_fk_chemical_class_id'])
            Notification.warning({
                title: "Atenção!",
                description: "A transportadora não opera com esse tipo de mercadoria (classes 1 e 7)",
            })
            this.setState('loadings', [])
        }
    }
}