import React from "react";
import { Checkbox, Panel } from "rsuite";

export class TermsAccept extends React.Component<any>{
    public state = {
        checked: this.props?.values?.budget_is_accept_term == true ? true : false
    }

    onChangeCheck = (value:any,checked:any)=>{
        this.props.values.budget_is_accept_term = checked
        this.setState({checked})
    }
    
    render() {
        return (<>
            <Panel bordered bodyFill style={{ padding: 8, width: '100%',color:"black" }}>
                <p><b>Termos de aceite</b></p>
                <p style={{marginTop:8}}>
                    <b>A aprovação da cotação via sistema contendo os dados cadastrais do cliente de cobrança + e-mail para envio do faturamento e HBL deverão ser realizadas antes da entrada / desova da unidade no terminal para abertura de referência, caso contrário poderá ocorrer custo adicional de posicionamento/pesagem. Os valores acima referem-se a custos de transporte Brasmeg, cabendo cobrança do terminal das taxas de DTA-HUBPORT e/ou Armazenagem.</b>
                </p>
                <p>
                <Checkbox id='checkboxtermos' onChange={this.onChangeCheck} disabled={this.state.checked} defaultChecked={this.state.checked} checkable={true} ><b>Eu li e concordo com os termos de aceite</b> </Checkbox>
                </p>
            </Panel>
        </>)
    }
}