import React from "react";
import { Button, Modal, Panel, PanelGroup, Timeline, Uploader } from 'rsuite';
import { FileInput } from "../../../FileInput";
import _ from "lodash";


export class BudgetAcceptDocumentNecessary extends React.Component<any, {}> {
    state = {
        showModalInfo: false
    }

    componentDidMount(): void {
        console.log('teste ::> ', _.filter(this.props.values.files, { 'file_relation_field': 'budget_accept_hbl_signed' }) ?? [])
    }

    renderTitle = () => {
        return (
            <>
                <Modal show={this.state.showModalInfo} onHide={() => this.setState({ showModalInfo: false })}>
                    <Modal.Header>
                        Informações complementares
                    </Modal.Header>
                    <Modal.Body>
                        <Timeline>
                            <Timeline.Item>Para faturas com Incoterm EXW -enviar as taxas de origem para que
                                possamos somar ao valor da fatura comercial, e assim declarar o valor FOB na DTA;
                            </Timeline.Item>
                            <Timeline.Item>Para faturas com Incoterm CIF/CIP – enviar por e-mail o valor do seguro
                                (caso não esteja destacado na fatura comercial), para que possamos subtrair do valor
                                da fatura comercial junto com o frete marítimo, e assim declarar o valor FOB na DTA;
                            </Timeline.Item>
                            <Timeline.Item>
                                Para Incoterms CFR – caso o valor do frete marítimo esteja descrito na fatura, seja diferente
                                do informado no CE MERCANTE, informar qual devemos considerar, para que possamos subtrair do
                                valor da fatura comercial, e assim declarar o valor FOB na DTA.
                            </Timeline.Item>
                            <Timeline.Item>
                                Em caso de fatura sem valor comercial, deverá informar o motivo.
                            </Timeline.Item>
                        </Timeline>
                    </Modal.Body>
                </Modal>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 10
                    }}
                >
                    <div>
                        Cópia da Fatura Comercial original assinada, informando Incoterm e moeda negociada observando a necessidade de envio de:
                        o Para faturas com Incoterm EXW -enviar as taxas de origem para que possamos somar ao valor da fatura comercial, e assim declarar o valor FOB na DTA;
                    </div>
                    <Button
                        color="blue"
                        onClick={() => this.setState({ showModalInfo: true })}
                    >
                        <i className="fas fa-info-circle"></i>
                    </Button>
                    {/* <div>
                    </div> */}
                </div>
            </>
        )
    }


    render(): React.ReactNode {
        return (
            <div>
                {this.props.parentValues.budget_fk_charging_type_id == 1 ? (
                    <>
                        <h3>Documentos Necessários para DTA Importação:</h3>
                        <PanelGroup accordion>
                            <Panel header="Cópia do HBL original assinado, com porto de descarga Santos">
                                <div>
                                    <FileInput
                                        listType="picture-text"
                                        multiple={false}
                                        readonly={false}
                                        name={'budget_accept_hbl_signed'}
                                        relation={this.props.form.$GLOBALS.table}
                                        guid={this.props.guid}
                                        value={_.filter(this.props.values.files, { 'file_relation_field': 'budget_accept_hbl_signed' }) ?? []}
                                    />
                                </div>
                            </Panel>
                            <Panel
                                header={this.renderTitle()}
                            >
                                <div>
                                    <FileInput
                                        listType="picture-text"
                                        multiple={false}
                                        readonly={false}
                                        name={'budget_accept_copy_invoice'}
                                        relation={this.props.form.$GLOBALS.table}
                                        guid={this.props.guid}
                                        value={_.filter(this.props.values.files, { 'file_relation_field': 'budget_accept_copy_invoice' }) ?? []}
                                    />
                                </div>
                            </Panel>
                            <Panel header="Cópia do Packing list original assinado">
                                <div>
                                    <FileInput
                                        listType="picture-text"
                                        multiple={false}
                                        readonly={false}
                                        name={'budget_accept_copy_packing_list'}
                                        relation={this.props.form.$GLOBALS.table}
                                        guid={this.props.guid}
                                        value={_.filter(this.props.values.files, { 'file_relation_field': 'budget_accept_copy_packing_list' }) ?? []}
                                    />
                                </div>
                            </Panel>
                            <Panel header="Tela do CE Mercante “dados do conhecimento”">
                                <div>
                                    <FileInput
                                        listType="picture-text"
                                        multiple={false}
                                        readonly={false}
                                        name={'budget_accept_view_merchant_data'}
                                        relation={this.props.form.$GLOBALS.table}
                                        guid={this.props.guid}
                                        value={_.filter(this.props.values.files, { 'file_relation_field': 'budget_accept_view_merchant_data' }) ?? []}
                                    />
                                </div>
                            </Panel>
                            <Panel header="Tela do CE Mercante “item de carga detalhado“">
                                <div>
                                    <FileInput
                                        listType="picture-text"
                                        multiple={false}
                                        readonly={false}
                                        name={'budget_accept_view_merchant_charge'}
                                        relation={this.props.form.$GLOBALS.table}
                                        guid={this.props.guid}
                                        value={_.filter(this.props.values.files, { 'file_relation_field': 'budget_accept_view_merchant_charge' }) ?? []}
                                    />
                                </div>
                            </Panel>
                        </PanelGroup>
                        <Panel bordered bodyFill style={{
                            marginBottom: 20,
                            padding: 10,
                            width: '100%',
                            color: "black",
                            borderRadius: 10,
                            boxShadow: '5px 6px 2px rgba(0, 0, 0, 0.1) '
                        }}>
                            <h4
                                style={
                                    {
                                        alignItems: 'center',
                                        gap: 10
                                    }
                                }
                            >Importante <i className="fas fa-info-circle"></i></h4>
                            <p>
                                Será necessário a inclusão da Transportadora como Beneficiário
                                no radar do importador: BRASMEG TRANSPORTES LTDA - CNPJ: 13.520.755/0001-69
                            </p>
                        </Panel>
                    </>
                ) : (
                    <>
                        <h3>Documentos Necessários para DTA Exportação:</h3>
                        <PanelGroup accordion>
                            <Panel header="DUE Liberada para carregamento">

                                <div>
                                    <FileInput
                                        listType="picture-text"
                                        multiple={false}
                                        readonly={false}
                                        name={'budget_accept_copy_due'}
                                        relation={this.props.form.$GLOBALS.table}
                                        guid={this.props.guid}
                                        value={_.filter(this.props.values.files, { 'file_relation_field': 'budget_accept_copy_due' }) ?? []}
                                    />
                                </div>
                            </Panel>
                            <Panel header="Nota Fiscal">
                                <div>
                                    <FileInput
                                        listType="picture-text"
                                        multiple={false}
                                        readonly={false}
                                        name={'budget_accept_fiscal_note'}
                                        relation={this.props.form.$GLOBALS.table}
                                        guid={this.props.guid}
                                        value={_.filter(this.props.values.files, { 'file_relation_field': 'budget_accept_fiscal_note' }) ?? []}
                                    />
                                </div>
                            </Panel>
                            <Panel header="Planilha de exportação devidamente preenchida">
                                <div>
                                    <FileInput
                                        listType="picture-text"
                                        multiple={false}
                                        readonly={false}
                                        name={'budget_accept_export_spreadsheet'}
                                        relation={this.props.form.$GLOBALS.table}
                                        guid={this.props.guid}
                                        value={_.filter(this.props.values.files, { 'file_relation_field': 'budget_accept_export_spreadsheet' }) ?? []}
                                    />
                                </div>
                            </Panel>
                        </PanelGroup>
                        <Panel bordered bodyFill style={{
                            marginBottom: 20,
                            padding: 10,
                            width: '100%',
                            color: "black",
                            borderRadius: 10,
                            boxShadow: '5px 6px 2px rgba(0, 0, 0, 0.1) '
                        }}>
                            <h4
                                style={
                                    {
                                        alignItems: 'center',
                                        gap: 10
                                    }
                                }
                            >Importante <i className="fas fa-info-circle"></i></h4>
                            <p>
                                Para carregamento os lotes devem estar com a DUE liberada e sem nenhuma
                                pendência financeira junto ao terminal de origem.
                            </p>
                        </Panel>
                    </>
                )}
            </div>
        )
    }
}