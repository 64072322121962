import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { Notification } from "rsuite";

let oldParamValues: any = {}

const budgetCalculate = async (props: any) => {
    try {
        let paramField = props?.values?.budget_fk_param_id
        if (!paramField) {
            Notification.error({ title: "Erro ao calcular!", description: "O campo Origem/Destino deve estar preenchido!", key: "errocalculo" })
            return
        }

        if (!oldParamValues?.[paramField] && props?.values?.budget_fk_status_budget_id === 1) {
            await axios.get("/api/v1/params/" + paramField).then((res) => {
                oldParamValues[paramField] = res.data.item
            }).catch(() => { })
        }

        let paramData = oldParamValues[paramField]


        if (paramData?.param_dispatch) {
            //seta valor despacho
            props.setFieldValue("budget_merchandise_dispatch", paramData?.param_dispatch);
        }
        if (paramData?.param_toll) {
            //seta valor pedagio
            props.setFieldValue("budget_toll", paramData?.param_toll);
        }
        if (paramData?.param_cubic_weight) {
            //seta valor WM
            props.setFieldValue("budget_wm_value", paramData?.param_cubic_weight)
        }
        if (paramData?.param_suspended_taxes && props?.values?.budget_merchandise_value) {
            props.setFieldValue("budget_merchandise_tax_suspended_value", (props?.values?.budget_merchandise_value * paramData?.param_suspended_taxes))
        }

        //calcular valor base
        if (props?.values?.budget_merchandise_weight && props?.values?.budget_merchandise_cubage) {
            // campos: Peso da Mercadoria E Cubagem(m³)            
            let valorBase = ((props?.values?.budget_merchandise_weight / paramData?.param_disparity) > props?.values?.budget_merchandise_cubage) ? (props?.values?.budget_merchandise_weight / paramData?.param_disparity) : props?.values?.budget_merchandise_cubage;
            props.setFieldValue("budget_merchandise_base_value", valorBase);
        }

        //calcular frete peso cubado // WM
        if (paramData?.param_cubic_weight && props?.values?.budget_merchandise_base_value) {
            props.setFieldValue("budget_shipping_cubed_weight", (paramData?.param_cubic_weight * props?.values?.budget_merchandise_base_value));
        }
        //calcular gerenciamento de riscos
        if (paramData?.param_gris && props?.values?.budget_merchandise_value && props?.values?.budget_merchandise_tax_suspended_value) {
            props.setFieldValue("budget_risk_management", (props?.values?.budget_merchandise_value + props?.values?.budget_merchandise_tax_suspended_value) * paramData?.param_gris)
        }
        //calcula empilhamento
        if (props?.values?.budget_shipping_cubed_weight) {
            props.setFieldValue("budget_stacking", props?.values?.budget_is_stackable != true ? props?.values?.budget_shipping_cubed_weight : 0)
        }
        //calcular AD VALOREM
        if (paramData?.param_ad_valorem && props?.values?.budget_merchandise_value && props?.values?.budget_merchandise_tax_suspended_value) {
            props.setFieldValue("budget_ad_valorem", (props?.values?.budget_merchandise_value + props?.values?.budget_merchandise_tax_suspended_value) * paramData?.param_ad_valorem)
        }

        //calcular IMO
        if (paramData?.param_imo && props?.values?.budget_merchandise_base_value && paramData?.param_cubic_weight) {
            let wm = (props?.values?.budget_merchandise_base_value * paramData?.param_cubic_weight) * paramData?.param_imo
            props.setFieldValue("budget_imo", paramData?.param_imo ? wm : 0);
        }

        if (props?.values?.budget_is_anvisa) {
            if (props?.values?.budget_shipping_cubed_weight && paramData?.param_anvisa) {
                const AnvisaValue = props?.values?.budget_shipping_cubed_weight * paramData?.param_anvisa;
                props.setFieldValue("budget_anvisa", AnvisaValue);
            }
        } else {
            props.setFieldValue("budget_anvisa", 0);
        }

        if (props?.values?.budget_is_imo) {
            if (props?.values?.budget_shipping_cubed_weight && paramData?.param_imo) {
                const IMOValue = props?.values?.budget_shipping_cubed_weight * paramData?.param_imo;
                props.setFieldValue("budget_imo", IMOValue);
            }
        } else {
            props.setFieldValue("budget_imo", 0);
        }

        //calcular subtotal
        let subTotal =
            (props?.values?.budget_shipping_cubed_weight || 0) +
            (props?.values?.budget_merchandise_dispatch || 0) +
            (props?.values?.budget_toll || 0) +
            (props?.values?.budget_risk_management || 0) +
            (props?.values?.budget_stacking || 0) +
            (props?.values?.budget_ad_valorem || 0) +
            (props?.values?.budget_imo || 0) +
            (props?.values?.budget_anvisa || 0);

        //calcular icms
        // if (props?.values?.budget_fk_charging_type_id && subTotal) {
        //     if (props?.values?.budget_fk_charging_type_id == "1" && paramData?.param_icms) {
        //         let calcICMS = (subTotal / paramData?.param_icms) - subTotal
        //         subTotal = subTotal + calcICMS
        //         props.setFieldValue("budget_icms", calcICMS)
        //     }
        // }

        //calcular subtotal / total / ICMS
        if (props?.values?.budget_fk_charging_type_id == "1" && paramData?.param_icms) {
            let subTotalValue = subTotal
            let calcICMS = (subTotalValue / paramData?.param_icms) - subTotalValue
            if (!calcICMS || isNaN(calcICMS)) {
                calcICMS = 0
            }
            let calculo = subTotalValue + calcICMS;
            // subTotal = subTotal + calcICMS;
            props.setFieldValue("budget_icms", calcICMS)
            props.setFieldValue("budget_subtotal", subTotal ?? 0)
            props.setFieldValue("budget_total", calculo ?? 0)

        } else {

            props.setFieldValue("budget_total", subTotal ?? 0)
            props.setFieldValue("budget_subtotal", subTotal ?? 0)
        }

        if (!props?.values?.budget_is_stackable) {
            if (props?.values?.budget_shipping_cubed_weight && paramData?.param_stackable) {
                const stackableValue = props?.values?.budget_shipping_cubed_weight * paramData?.param_stackable;
                props.setFieldValue("budget_stacking", stackableValue);
            }
        }

    } catch (e) {
        console.log("Calculo", e)
    }
    return
}

export class BudgetWmValueEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        budgetCalculate(this)
    }
}
export class BudgetFkParamIdEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        // if (value) {
        //     console.log("entoru value onchahfan param", value)
        //     this.setField('budget_fk_param_id', { readonly: false });
        // }

        // axios.get("/api/v1/params/" + value).then((res) => {
        //     const paramChargingTypes = res.data?.item?.param_charging_types;
        //     console.log(paramChargingTypes);
        //     console.log("budget_fk_charging_type_id", formValues.budget_fk_charging_type_id);
        //     if (paramChargingTypes.includes(parseInt(formValues.budget_fk_charging_type_id))) {
        //         console.log("inclui");
        //     }
        // });

        budgetCalculate(this)
    }
}

export class BudgetIsAnvisaEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        budgetCalculate(this)
    }
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setField('budget_fk_chemical_class_id', {
                readonly: false
            });
        } else {
            this.setState('loadings', ['budget_fk_chemical_class_id']);
            setTimeout(() => {
                this.setFieldValue('budget_fk_chemical_class_id', undefined);
            }, 50);
            this.setField('budget_fk_chemical_class_id', {
                readonly: true
            });
            this.setState('loadings', []);
        }
        budgetCalculate(this);
    }
}

export class BudgetIsImoEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setField('budget_fk_chemical_class_id', {
                readonly: false,
                // required: true
            });
        } else {
            this.setState('loadings', ['budget_fk_chemical_class_id']);
            setTimeout(() => {
                this.setFieldValue('budget_fk_chemical_class_id', undefined);
            }, 50);
            this.setField('budget_fk_chemical_class_id', {
                readonly: true
            });
            this.setState('loadings', []);
        }
        budgetCalculate(this);
    }
}

export class BudgetIsStackableEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            Notification.warning({
                title: "Atenção!",
                description: "Durante a operação, caso identificado impossibilidade de remonte será cobrado taxa extra referente a NÃO Empilhamento"
            })
        }

        if (formValues.budget_stacking > 0 && value === true) {
            this.setState("loadings", ['budget_stacking']);
            this.setFieldValue('budget_stacking', 0);
            setTimeout(() => {
                this.setState("loadings", []);
            }, 500);
        }
        budgetCalculate(this)
    }
}

export class BudgetMerchandiseValueEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        budgetCalculate(this)
    }
}

export class BudgetMerchandiseWeightEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        budgetCalculate(this)
    }
}

export class BudgetMerchandiseCubageEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        budgetCalculate(this)
    }
}

export class BudgetMerchandiseDispatchEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        budgetCalculate(this)
    }
}