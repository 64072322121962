// budget_fk_charging_type_id

import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { Notification } from "rsuite";

export class BudgetFkChargingDescriptionIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'budget_is_imo',
            'budget_fk_chemical_class_id'
        ])
        if (value && value == 7) {
            this.setFieldValue('budget_is_imo', true)
            this.setField('budget_fk_chemical_class_id', {
                readonly: false,
                // required: true
            })
        } else if (value == 6 || value == 5) {
            this.setFieldValue('budget_is_anvisa', true)
            this.setFieldValue('budget_is_imo', false)
            this.setField('budget_fk_chemical_class_id', {
                readonly: false,
                required: false
            })
        } else if (value != 6 || value != 5) {
            this.setFieldValue('budget_is_anvisa', false)
        }

        if (value == 4) {
            this.setFieldValue('budget_is_imo', false);
            this.setField('budget_fk_chemical_class_id', {
                readonly: true
            });
        }

        this.setState('loadings', [])
    }
}

