import React from "react";
import { Loader, Modal, Tooltip, Whisper } from "rsuite";
import axios from "../../utilities/axios";
import Browserlize from "../Browserlize";
import { Permission } from "../Permission";

export class AdvancedSearchModal extends React.Component<any, {}> {
    public state = {
        isLoading: true,
        form: undefined,
        showModal: this.props.show
    };

    public select: any = {};

    componentDidUpdate(props: any) {
        //  console.log('teste ::: ', this.props.show)
        if (props.show === false && this.props.show === true) {
            this.setState({ showModal: this.props.show });
            var targetApi = ''

            if (this.props.crud) {
                targetApi = this.props.crud;
            } else {
                targetApi = this.props.api.split("/api/v1/").pop().split("/").shift();
            }
            // alert(this.props.crud);
            axios
                .get("/api/v1/" + targetApi + "/fields")
                .then((res) => {
                    if (res && res.data) {
                        if ((this.props.labelProps !== undefined) && (this.props.labelProps !== "")) {
                            this.select = { value: res.data.select.value, label: this.props.labelProps.split(".").pop() }
                        } else {
                            this.select = res.data.select;
                        }
                        this.setState({ form: res.data.fields, isLoading: false });
                        //console.log(res.data);
                    }
                })
                .catch((e) => { });
        }
        // //console.log(props.show,this.props.show);
    }

    onSelectItem = (rowData: any) => {
        console.log(this.select)
        if (this.select?.label && this.select?.value) {
            if (rowData[this.select.label] && rowData[this.select.value]) {
                // alert(JSON.stringify(this.props))
                // alert(JSON.stringify(rowData[this.select.label])+ ' - '+ JSON.stringify(rowData[this.select.value]));
                if (this.props.onSelect) {
                    // console.log("Select :::",this.select)
                    this.props.onSelect({
                        label: rowData[this.select.label],
                        value: rowData[this.select.value]?.toString(),
                    });
                    this.setState({ showModal: false })
                    this.props.onHide()
                }
            }
        }
    };

    actions = (rowData: any) => {
        return (
            <div>
                <Permission name={"people-read"}>
                    <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Selecionar</Tooltip>}>
                        <i onClick={() => this.onSelectItem(rowData)} className="fas text-success fa-fw mr-2 clickable fa-check-circle"></i>
                    </Whisper>
                </Permission>
            </div>
        );
    };

    onClose = () => {
        this.setState({ showModal: false })
        this.props.onHide()
    }

    render() {
        return (
            <>
                <Modal overflow={false} full show={this.state.showModal}>
                    <Modal.Header onHide={this.onClose}></Modal.Header>
                    <Modal.Body>
                        {this.state.isLoading === true && (
                            <div className="d-flex justify-content-center">
                                <Loader size={"md"} />
                            </div>
                        )}
                        {this.state.isLoading === false && this.state.form !== undefined && <Browserlize
                            modal={true} overrideActions={this.actions}
                            headers={false} uses={this.props.uses}
                            searchValue={this.props.searchValue}
                            form={this.state.form}
                        ></Browserlize>}
                        {/* {JSON.stringify(this.props.uses)} */}
                    </Modal.Body>
                </Modal>
                {this.state.showModal}
            </>
        );
    }
}
