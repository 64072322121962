import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col } from "rsuite";
import { Event } from "../Event";
import { withRouter } from "react-router";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
import { CustomModal } from "../../components/customModal/CustomModal"
import classNames from "classnames";
import { link } from "fs";
import { BudgetAcceptCustom } from "../../components/formfields/custom/budget/BudgetAcceptCustom";
import { BudgetActions } from '../../components/formfields/custom/budget/BudgetActions'
import _ from "lodash";
import dayjs from "dayjs";
import { UserContext } from "../../components/authProvider/main";


class BudgetFooterComponent extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;
    public state = {
        showModal: false,
        showModalSubmit: false,
        showModalDecline: false,
        show: false,
        showEmail: false,
        loading: false,
        showSituation: false,
        situacaoModal: "",
        started: false,
        loadingCompleteDocumentation: false,
        loadingValidateDocumentation: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false
    };


    print = () => {
        if (this.props?.values?.formValues?.budget_id) {
            this.setState({ loading: true })
            axios.get("/api/v1/print-budget/" + this.props?.values?.formValues?.budget_id).then((res) => {
                if (res.data && res.data.url) {
                    window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                }
                // Notification.success({
                //     title: "Serviço Clonado!",
                //     description: `O Serviço #${this.props?.values?.formValues?.param_id} Foi Clonado com Sucesso!`
                // })
                // this.props.history.push("/dashboard/configuracoes/params/"+res.data)
            }).catch((e) => {
                throw ("Erro")
            }).finally(() => {
                this.setState({ loading: false });
            });
        } else {
            throw ("Erro")
        }
    }

    changeSituation = ({ nextSituation, onSave }: { nextSituation: number, onSave?: boolean }) => {
        this.setState({ loading: true });

        axios
            .get(`/api/v1/budget/situation`,
                {
                    params: {
                        nextSituation: nextSituation,
                        budgetId: this.props?.values?.formValues?.budget_id
                    }
                }
            )
            .then((response) => {
                console.log({ response })

            })
            .catch((e) => {
                Notification.warning(
                    {
                        title: 'Atenção',
                        description: e.response.data.message
                    }
                )
            })
            .finally(() => {
                this.setState({ loading: false });

                if (onSave) {
                    // Preenchimento das datas
                    // if (nextSituation < this.props.values.formValues.budget_fk_status_budget_id) {
                    //     if (nextSituation === 8) {
                    //         this.props.values.formValues.budget_process_shipping = dayjs().format("DDMMYYYY");
                    //         this.props.values.formValues.budget_traffic_finish = "";
                    //         this.props.values.formValues.budget_finish_trip = "";
                    //         this.props.values.formValues.budget_start_trip = "";
                    //     }
                    //     if (nextSituation === 9) {
                    //         this.props.values.formValues.budget_start_trip = dayjs().format("DDMMYYYYHHmm");
                    //         this.props.values.formValues.budget_traffic_finish = "";
                    //         this.props.values.formValues.budget_finish_trip = "";
                    //     }
                    //     if (nextSituation === 12) {
                    //         this.props.values.formValues.budget_finish_trip = dayjs().format("DDMMYYYYHHmm");
                    //         this.props.values.formValues.budget_traffic_finish = "";
                    //     }
                    // }

                    // if (nextSituation > this.props.values.formValues.budget_fk_status_budget_id) {
                    //     if (nextSituation === 8) {
                    //         this.props.values.formValues.budget_process_shipping = dayjs().format("DDMMYYYY");
                    //     }
                    //     if (nextSituation === 9) {
                    //         this.props.values.formValues.budget_start_trip = dayjs().format("DDMMYYYYHHmm");
                    //     }
                    //     if (nextSituation === 12) {
                    //         this.props.values.formValues.budget_finish_trip = dayjs().format("DDMMYYYYHHmm");
                    //     }
                    //     if (nextSituation === 14) {
                    //         this.props.values.formValues.budget_traffic_finish = dayjs().format("DDMMYYYY");
                    //     }
                    // }

                    this.props.values.formValues.budget_fk_status_budget_id = nextSituation;

                    setTimeout(() => {
                        this.props.onSubmit();
                    }, 500);
                } else {
                    window.location.reload();
                }
            });
    }

    verifyIsCompleteDocumentation = async () => {
        this.setState({ loadingCompleteDocumentation: true });

        await axios
            .get(`/api/v1/budget_accepts/verify-complete-docs/${this.props.values.formValues.budget_id}`)
            .then((response) => {
                if (response.data.allRequiredFiles) {
                    this.changeSituation({ nextSituation: 10 });
                } else {
                    if (response.data.type === "error") {
                        Notification.warning(
                            {
                                title: 'Atenção!',
                                description: response.data.message,
                                duration: 4000
                            }
                        );
                    } else {
                        Notification.warning(
                            {
                                title: 'Atenção!',
                                description: 'É necessário anexar todos os arquivos da documentação para continuar!',
                                duration: 4000
                            }
                        );
                    }
                }
            })
            .catch((e) => {
                console.error(e);
            }).finally(() => {
                this.setState({ loadingCompleteDocumentation: false });
            });
    }

    validateDocumentation = async () => {
        this.setState({ loadingValidateDocumentation: true });

        await axios
            .get(`/api/v1/budget_accepts/verify-complete-docs/${this.props.values.formValues.budget_id}`)
            .then((response) => {
                if (response.data.allRequiredFiles) {
                    this.changeSituation({ nextSituation: 5 });
                } else {
                    Notification.warning(
                        {
                            title: 'Atenção!',
                            description: 'Existem campos faltando para completar a ação!',
                            duration: 4000
                        }
                    );
                }
            })
            .catch((e) => {
                console.error(e);
            }).finally(() => {
                this.setState({ loadingValidateDocumentation: false });
            });
    }

    recuseDocumentation = () => {
        const { formValues } = this.props.values
        const checkedIfExistFollowUp = _.find(formValues.budget_followups, { 'budget_followup_documentation_is_reject': true }) ? true : false;
        if (!checkedIfExistFollowUp) {
            Notification.warning(
                {
                    title: 'Atenção!',
                    description: 'Para a rejeição é necessario adicionar um motivo ao FollowUp'
                }
            )
            return
        }
        this.props.onSubmit(
            () => {
                this.changeSituation({ nextSituation: 11 })
            }
        )
    }

    decline = () => {
        if (this.props.values.formValues?.budget_followups.length === 0) {
            Notification.warning(
                {
                    title: 'Atenção',
                    description: "É preciso criar pelo menos um followup para realizar esta ação!"
                }
            );
            this.setState({ showModalDecline: false });
            return
        }

        this.props.onSubmit(
            () => {
                this.changeSituation({ nextSituation: 7 })
            }
        );
    }

    onSubmit = () => {
        if (this.props.values.formValues.budget_is_stackable) {
            this.setState({ showModalSubmit: true })
            return
        }
        this.props.onSubmit()
    }


    render() {
        return (
            <div
                style={
                    {
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 4
                    }
                }
            >
                <CustomModal
                    title={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 10
                            }}
                        >
                            <div>
                                Lembrete
                            </div>
                            <i className="fas fa-exclamation-circle"></i>
                        </div>
                    }
                    confirmColor="green"
                    onConfirm={() => { this.setState({ showModalSubmit: false }); this.props.onSubmit(); }}
                    closeModal={() => this.setState({ showModalSubmit: false })}
                    onCancel={() => this.setState({ showModalSubmit: false })}
                    show={this.state.showModalSubmit}
                    body={
                        <p
                            style={
                                {
                                    fontWeight: 800
                                }
                            }
                        >
                            Durante a operação, caso identificado impossibilidade de remonte será cobrado taxa extra referente a Não Empilhamento
                        </p>
                    }
                />
                <CustomModal
                    title={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 10
                            }}
                        >
                            <div>
                                Aviso
                            </div>
                            <i className="fas fa-exclamation-triangle"></i>
                        </div>
                    }
                    confirmColor="green"
                    onConfirm={this.decline}
                    closeModal={() => this.setState({ showModalDecline: false })}
                    onCancel={() => this.setState({ showModalDecline: false })}
                    show={this.state.showModalDecline}
                    body={
                        <p
                            style={
                                {
                                    fontWeight: 800
                                }
                            }
                        >
                            Tem certeza que deseja declinar orçamento?
                        </p>
                    }
                />
                <Button color="green" onClick={this.onSubmit} loading={this.state.loading}>
                    <i className="fas fa-fw fa-save mr-2"></i>
                    Gravar
                </Button>
                <Button loading={this.state.loading} onClick={() => this.print()} color={"blue"} className="ml-4">
                    <i className="fas fa-copy fa-fw mr-2"></i>
                    Imprimir Orçamento
                </Button>
                {this.props.values.formValues.budget_fk_status_budget_id === 1 ? (
                    <div
                        style={
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 10

                            }
                        }
                    >
                        {/* <BudgetAcceptCustom
                            ButtonOpenName="Aprovar"
                            onSubmit={this.props.onSubmit}
                            valuesProps={this.props.values}
                            icon={<i className="fas fa-check-double mr-2"></i>}
                        /> */}
                        <Button
                            appearance="primary"
                            color="blue"
                            onClick={() => this.changeSituation({ nextSituation: 2 })}
                        >
                            <i className="fas fa-check-double mr-2"></i>
                            Aprovar
                        </Button>
                        <Button
                            appearance="primary"
                            color="red"
                            onClick={() => this.setState({ showModalDecline: true })}
                        >
                            Declinar
                        </Button>
                    </div>
                ) : (
                    <>
                        {(this.props.values.formValues.budget_fk_status_budget_id == 4 || this.props.values.formValues.budget_fk_status_budget_id == 11 || this.props.values.formValues.budget_fk_status_budget_id == 2) && (
                            <Button
                                loading={this.state.loadingCompleteDocumentation}
                                color="blue"
                                style={
                                    {
                                        display: 'flex',
                                        flex: 'row',
                                        gap: 10,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }
                                }
                                onClick={() => this.verifyIsCompleteDocumentation()}
                            >
                                <i className="fas fa-spell-check"></i>
                                Documentação Completa
                            </Button>
                        )}
                        {(this.props.values.formValues.budget_fk_status_budget_id == 10 && this.context.data?.user_fk_role_id != 6) && (
                            <>
                                <Button
                                    loading={this.state.loadingValidateDocumentation}
                                    color="green"
                                    style={
                                        {
                                            display: 'flex',
                                            flex: 'row',
                                            gap: 10,
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }
                                    }
                                    onClick={() => this.validateDocumentation()}
                                >
                                    <i className="fas fa-spell-check"></i>
                                    Documentos Validados
                                </Button>
                                <Button
                                    loading={this.state.loading}
                                    color="red"
                                    style={
                                        {
                                            display: 'flex',
                                            flex: 'row',
                                            gap: 10,
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }
                                    }
                                    onClick={() => this.recuseDocumentation()}
                                >
                                    <i className="fas fa-times"></i>
                                    Documentos Rejeitados
                                </Button>
                            </>
                        )}
                        {([5, 6, 8, 9, 12, 14].includes(this.props.values.formValues.budget_fk_status_budget_id) && this.context.data.role?.role_name !== "CLIENTE") && (
                            <>
                                <BudgetActions
                                    changeSituation={this.changeSituation}
                                />
                            </>
                        )}
                        <BudgetAcceptCustom
                            ButtonOpenName="Anexar Documentos"
                            onSubmit={this.props.onSubmit}
                            valuesProps={this.props.values}
                            icon={<i className="fas fa-file-alt"></i>}
                        />
                    </>
                )}
            </div>
        );
    }
}

const BudgetFooterWithRouter = withRouter(BudgetFooterComponent);


class BudgetFooterComponentAdd extends React.Component<any, {}> {

    public state = {
        loading: false,
        showModalSubmit: false
    };


    onSubmit = () => {
        if (this.props.values.formValues.budget_is_stackable) {
            this.setState({ showModalSubmit: true })
            return
        }

        this.props.onSubmit()
    }

    render(): React.ReactNode {
        return (
            <>
                <CustomModal
                    title={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 10
                            }}
                        >
                            <div>
                                Lembrete
                            </div>
                            <i className="fas fa-exclamation-circle"></i>
                        </div>
                    }
                    confirmColor="green"
                    onConfirm={() => { this.setState({ showModalSubmit: false }); this.props.onSubmit(); }}
                    closeModal={() => this.setState({ showModalSubmit: false })}
                    onCancel={() => this.setState({ showModalSubmit: false })}
                    show={this.state.showModalSubmit}
                    body={
                        <p
                            style={
                                {
                                    fontWeight: 800
                                }
                            }
                        >
                            Durante a operação, caso identificado impossibilidade de remonte será cobrado taxa extra referente a Não Empilhamento
                        </p>
                    }
                />

                <Button color="green" onClick={this.onSubmit} loading={this.state.loading}>
                    <i className="fas fa-fw fa-save mr-2"></i>
                    Gravar
                </Button>
            </>
        )
    }
}

const BudgetFooterAddWithRouter = withRouter(BudgetFooterComponentAdd);


export class BudgetFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <BudgetFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };

    public onRenderAddFooter = (onSubmit: any) => {
        return (
            <>
                <BudgetFooterAddWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    }
}