import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";


export class BudgetEvents extends Event {
    public onLoad = (value: any, setField: any, setState: any, formValues: any) => {

        const valuesChargingType = this.globalProps.values?.charging_types
        const valuesBudgetPChargingDescription = this.globalProps.values?.budget_fk_charging_description_id

        if (valuesChargingType && this?.globalProps?.isEdit) {
            if (valuesChargingType.charging_type_is_reference_hbl == true) {
                this.setField('budget_reference_hbl', { required: true })
            }
            if (valuesChargingType.charging_type_is_reference_reservation == true) {
                this.setField('budget_reference_reserv', { required: true })
            }
        }

        if (valuesBudgetPChargingDescription && this?.globalProps?.isEdit) {
            if (valuesBudgetPChargingDescription == 7) {
                this.setField('budget_fk_chemical_class_id', {
                    readonly: false,
                    required: true
                })
            }
        }

        let user: any;
        axios.get("/api/v1/me")
            .then((res: any) => {
                console.log("ress user", res.data)
                user = res.data.user;
                this.setState("loadings", ['budget_fk_user_id', 'budget_seller_fk_user_id']);

                if (user?.user_fk_role_id == 6 && (!this.globalProps.values?.budget_cpfcnpj && !this.globalProps.values?.budget_client_name && !this.globalProps.values?.budget_client_email)) { //CLIENTE
                    this.setFieldProperty("budget_fk_user_id", "readonly", true)
                    this.setFieldValue("budget_fk_user_id", user?.user_id);
                    this.setFieldProperty("budget_seller_fk_user_id", "readonly", true)
                    this.setFieldValue("budget_cpfcnpj", user?.user_cpf_cnpj);
                    this.setFieldValue("budget_client_name", user?.user_name);
                    this.setFieldValue("budget_client_email", user?.user_email);

                    this.setField('budget_process_shipping', { readonly: true });
                    this.setField('budget_start_trip', { readonly: true });
                    this.setField('budget_finish_trip', { readonly: true });
                    this.setField('budget_traffic_finish', { readonly: true });

                    if (user?.user_seller_fk_user_id) {
                        this.setFieldValue("budget_seller_fk_user_id", user.user_seller_fk_user_id);
                    }
                }

                if (user?.user_fk_role_id == 4) { //VENDEDOR
                    this.setFieldValue("budget_seller_fk_user_id", user.user_id);
                    this.setFieldProperty("budget_seller_fk_user_id", "readonly", true)
                }

                this.setState("loadings", []);
            });

        if (!this?.globalProps?.isEdit) {
            axios.get("/api/v1/budgets/latest").then((res: any) => {
                this.setFieldValue(
                    "budget_code", "BRAS" + ("000000" + res?.data?.next).slice(res?.data?.next?.toString().length) + "/" + (dayjs().get('year')).toString().slice(2)
                );

            })
            if (!this.globalProps.values?.budget_fk_status_budget_id) {
                this.setFieldValue("budget_fk_status_budget_id", 1);
            }
        }

        this.setState("loadings", [])
    }

}