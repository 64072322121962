import { Event } from "../Event";

export class UserFkRoleIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (this.globalProps.values.user_fk_role_id === "6") {
            this.setField('user_merchandise_limit_value', {
                hidden: false
            });
        } else {
            this.setField('user_merchandise_limit_value', {
                hidden: true
            });
        }
    }
}