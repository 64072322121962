import React from "react";
import { Table, SelectPicker, Loader, CheckPicker } from "rsuite";
import axios from "../utilities/axios";
import _, { result } from "lodash";

export class InputSelect extends React.Component<any, {}> {
  public state: any = {
    loading: true,
    oldId: null,
    data: []
  }

  async getStores() {
    this.setState({ loading: true })
    await axios.get('api/v1/' + this.props.crud + '/' + this.props.id)
      .then((res: any) => {
        this.setState({
          data: res.data.item
        })
      })
      .catch((e) => console.log(e.message))
      .finally(() => {  this.setState({ loading: false }) })
  }

  updateData = () => {
    if (this.state.data.length === 0) {
      this.getStores();
    }
  };

  renderMenu = (menu: any) => {
    if (this.state.loading) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <Loader /> Carregando...
        </p>
      );
    }
    return menu;
  };

  componentDidMount() {
    this.getStores()
  }


  handleSelectChange = (value: any) => {
    this.setState({ selectedValue: value });
    this.props.onChange(value);
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.id != this.state.oldId) {
      this.setState({ oldId: this.props.id })
      this.getStores()
    }
  }

  render() {
    const { data } = this.state
    const field = this.props.field
    return (
      <div style={{ display: "flex", flexDirection: 'column', alignItems: 'stretch', width: '100%' }}>
        {this.state.loading && (
          <p style={{ padding: 1, color: '#999' }}>
            Carregando...
          </p>)
        }
        {!this.state.loading && data && (<p>
          {data?.[field]}
        </p>)}
      </div>
    )
  }
}