import _ from "lodash";
import React from "react";
import { CheckTreePicker as RSuiteCheckTreePicker } from "rsuite";
import axios from "../../../utilities/axios";

export class CheckTreePickerAllClients extends React.Component<any, {}> {
    public state = {
        preloadedData: [],
        data: this.props.data ?? [],
        inputValue: "",
        loading: true,
        value: this.props.value ?? null,
    };

    componentDidMount() {
        this.handleUpdate();
    }


    componentDidUpdate(props: any) {

    }

    find(text: any, array: any): any {
        var result = array.filter((item: any) => {
            if (item['label'] && item['label'].toLocaleLowerCase().includes(text.toLocaleLowerCase())) {
                if (item['children']) {
                    return this.find(text, item['children']).length > 0;
                } else {
                    return true;
                }
            }
            return false;
        })
        // array.some((o: any) => (o.label === text && (result = o)) || (result = this.find(text, o.children || [])));
        return result;
    }

    handleUpdate = (value: any = null) => {
        // alert(value);

        console.log("props", this.props)
        this.setState({ loading: true });
        axios
            .get(`/api/v1/users/select?params=view_crud&CheckTreePicker=true&search=${value}`) // &CheckTreePicker=true
            .then((res) => {
                var arr: any = [...res.data.items];

                const rootOficinasNode = {
                    label: 'Todas',
                    value: 'all',
                    children: arr,
                };

                if (this.state.preloadedData) {
                    arr = _.uniqBy([...this.state.preloadedData, rootOficinasNode], "value");
                } else {
                    arr = [rootOficinasNode];
                }

                if (value) {
                    arr = this.find(value, arr);
                    //(arr);
                }
                this.setState({ data: arr, loading: false });
            })
            .catch((e) => {
                this.setState({ loading: false });
            })
            .finally(() => {
                // var searchInput = document.getElementsByClassName("rs-picker-search-bar-input")[0] as HTMLInputElement;
                // searchInput.focus();
            });
    };

    onChange = (incomingValue: any) => {
        var value = _.uniq(incomingValue);

        if (value.includes('all')) {
            const allValues = this.state.data.reduce((acc: any, node: any) => {
                if (node.children) {
                    acc.push(...node.children.map((child: any) => child.value));
                } else {
                    acc.push(node.value);
                }
                return acc;
            }, []);

            value = _.uniq(allValues);
        }

        if (this.props.onChange) {
            this.props.onChange(value, this.state.data);
        }
        this.setState({ value });
        console.log("value check tree", this.state.value)
    };

    onSearch = (value: any) => {
        alert(value)
    }

    public timeout: any = null;
    onChangeInput = (value: string) => {
        //(value);
        // this.setState({ inputValue: value });
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.handleUpdate(value);
        }, 600);
    };


    shouldComponentUpdate(props: any, state: any) {
        if (this.state.inputValue !== state.inputValue) {
            return false;
        }
        return true;
    }

    defaltValueSelect: any = (value: any) => {
        for (let i in value) {
            value[i] = parseInt(value[i])
        }
        return value;
    }

    render() {
        // if(this.props.inline === false) {
        return <>
            <label className="rs-control-label">{this.props.label}</label>
            <RSuiteCheckTreePicker
                block
                style={this.props.style}
                placeholder={"Selecione..."}
                preventOverflow
                virtualized
                defaultExpandAll
                cascade={true}
                onChange={this.onChange} /*onSearch={this.onChangeInput}*/
                onOpen={this.handleUpdate} value={this.state.value}
                defaultValue={this.defaltValueSelect(this.state.value)}
                data={this.state.data}
                disabled={this.props.disabled}
                {...this.props}
            />
            {/* {JSON.stringify(this.state.value)} */}
        </>;
        // }
        // return (
        //     <>
        //         <InputGroup style={{ width: "100%" }}>
        //             <Input onChange={this.onChangeInput} />
        //             <InputGroup.Button>
        //                 {this.state.loading && <Loader></Loader>}
        //                 {!this.state.loading && <i className="fas fa-search"></i>}
        //             </InputGroup.Button>
        //         </InputGroup>

        //         <CheckTree virtualized cascade={true} onChange={this.onChange} onOpen={this.handleUpdate} value={this.state.value} data={this.state.data} />


        //     </>
        // );
    }
}
