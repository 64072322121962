import React from "react";
import { BlockPicker } from "react-color";
import { Button, Popover, Whisper } from "rsuite";

const defaultColors = [
    "#6bac6a",
    "#53d7ff",
    "#ecad4e",
    "#c836b1",
    "#da4242",
    //line 2
    "#4caf50",
    "#36a5eb",
    "#ffb533",
    "#6a30d1",
    "#ff5733",
    //line 3
    "#eee",
    "#333",
    "#15b3be",
    "#0096a0",
    "#005664",
];
export class ColorPicker extends React.Component<any, {}> {
    public state: any = {
        color: { hex: this.props.value ?? "#333333" },
    };

    onChange = (color: any) => {
        this.props.onChange(color.hex);
    };

    render() {
        return (
            <div className="color-picker w-100">
                <Whisper
                    trigger="click"
                    placement="bottom"
                    speaker={
                        <Popover>
                            <BlockPicker colors={defaultColors} onChangeComplete={this.onChange} onChange={(color: any) => this.setState({ color })} color={this.state.color.hex} />
                        </Popover>
                    }>
                    <Button className="w-100">
                        <div className="color-block" style={{ backgroundColor: this.state.color.hex }}></div>
                    </Button>
                </Whisper>
            </div>
        );
    }
}
