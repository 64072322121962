import axios from "../utilities/axios";
import React from "react";
import { Loader, Button, CheckTreePicker, ControlLabel, FormGroup, Modal } from "rsuite";
import { TModal } from "./modalTabs/main";
import { SortableArray } from "./sortableArray/main";
import _ from "lodash";

export class ColumnEditor extends React.Component<any, {}> {
    public state: { isOpen: boolean; loading: boolean; data: any[]; order: string[]; selected: string[] } = {
        isOpen: this.props.isOpen,
        loading: true,
        data: [],
        order: [],
        selected: [],
    };

    componentDidUpdate(props: any) {
        if (this.state.isOpen !== this.props.isOpen) {
            this.setState({ isOpen: this.props.isOpen }, () => {
                if (this.state.isOpen) {
                    this.loadAvailableFields();
                }
            });
        }
    }

    parseResponseToSelect = (data: any[]) => {
        return _.map(
            _.filter(data, (item, index: any) => {
                return index !== "$GLOBALS" && item.canBrowse === true;
            }),
            (item, index) => {
                return {
                    label: (item?.label).replace(/<\/?[^>]+(>|$)/g, ""),
                    value: item.name,
                    name: item.name,
                };
            },
        );
    };

    loadAvailableFields = () => {
        this.setState({ loading: true });
        axios.get("/api/v1/me").then((res) => {
            const filterSetting = _.find(res.data.user.user_settings, {
                user_setting_key: this.props.page + "-filters",
            });
            var selected: string[] = [];
            if (filterSetting && filterSetting.user_setting_value) {
                selected = JSON.parse(filterSetting.user_setting_value);
            }
            axios
                .get("/api/v1/" + this.props.page + "/fields")
                .then((res) => {
                    if (res.data?.status === 200 && res.data.fields) {
                        if (!filterSetting) {
                            selected = _.map(res.data.fields, (item) => {
                                return item.name;
                            });
                        }
                        this.setState({ data: this.parseResponseToSelect(res.data.fields), selected });
                    }
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    };

    getSortableData = () => {
        return _.sortBy(
            _.filter(this.state.data, (item: { name: string }) => {
                return this.state.selected.includes(item.name);
            }),
            (item) => {
                return this.state.selected.indexOf(item.name);
            },
        );
    };

    onSave = () => {
        this.setState({ loading: true });
        axios
            .post("/api/v1/me/" + this.props.page + "-filters", {
                value: this.state.selected,
            })
            .then(function (res) {})
            .finally(() => {
                this.setState({ loading: false });
                if(this.props.onSave) {
                    this.props.onSave(this.state.selected)
                }
            })
            .catch((e) => {});
    };

    onSort = (selected: any) => {
        // alert(JSON.stringify(order));
        this.setState({ selected });
    };

    onSelect = (selected: any) => {
        this.setState({ selected });
    };

    render() {
        return (
            <>
                <Modal show={this.state.isOpen} size={"sm"} className="centered">
                    <Modal.Header onHide={this.props.onHide}>
                        <Modal.Title>
                            <i className="fas fa-fw mr-2 fa-cog" style={{ color: "#0096a0", fontSize: 24 }}></i>
                            Configurações
                            {/* <Button onClick={this.loadAvailableFields}>
                                <i className="fas fa-sync"></i>
                            </Button> */}
                        </Modal.Title>
                    </Modal.Header>
                    <TModal.Tabs
                        tabs={[
                            { key: "colunas", label: "Colunas" },
                            { key: "ordenacao", label: "Ordenação" },
                        ]}>
                        <TModal.Tab tab={"colunas"}>
                            <Modal.Body>
                                <div className="row">
                                    {!this.state.loading && (
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <ControlLabel>Colunas visiveis</ControlLabel>
                                                <div className="rs-form-control-wrapper">
                                                    <CheckTreePicker
                                                        preventOverflow={true}
                                                        placement={"auto"}
                                                        value={this.state.selected}
                                                        onChange={this.onSelect}
                                                        cascade={false}
                                                        defaultExpandAll
                                                        data={this.state.data}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                    )}
                                    {this.state.loading && (
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <Loader size={"md"} />
                                        </div>
                                    )}
                                </div>
                                {/* {JSON.stringify(this.state.data)} */}
                            </Modal.Body>
                        </TModal.Tab>
                        <TModal.Tab tab={"ordenacao"}>
                            <Modal.Body>
                                <div className="row">
                                    {!this.state.loading && (
                                        <div className="col-md-12">
                                            <SortableArray onChange={this.onSort} items={this.getSortableData()} />
                                        </div>
                                    )}
                                    {this.state.loading && (
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <Loader size={"md"} />
                                        </div>
                                    )}
                                </div>
                            </Modal.Body>
                        </TModal.Tab>
                    </TModal.Tabs>

                    <Modal.Footer>
                        <Button onClick={this.props.onHide}>
                            Cancelar
                        </Button>
                        <Button color={"green"} onClick={this.onSave}>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
